import React from 'react'
import _times from 'lodash/times'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { TitleSection } from '../../common/Index.styled'
import Section from '../../common/Section'
import { ContractButtonSmall } from '../SolutionDetail.styled'

const PageContentLegal = () => {
  return (
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.legal.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedHTMLMessage id="solutions.legal.content.p1" />
        </p>
        <ul>
          {_times(5, String).map((value, key) => (
            <li key={key}>
              <FormattedMessage
                id={`solutions.legal.content.list1.item${value}`}
              />
            </li>
          ))}
        </ul>
        {/* <h3>
          <FormattedHTMLMessage id="solutions.legal.content.subtitle1" />
        </h3> */}
        <h4>
          <FormattedHTMLMessage id="solutions.legal.content.subtitle2" />
        </h4>
        <ul>
          {_times(11, String).map((value, key) => (
            <li key={key}>
              <FormattedMessage
                id={`solutions.legal.content.list2.item${value}`}
              />
            </li>
          ))}
        </ul>

        <h4>b).- Solución en migración</h4>
        <p>
          Nuestros servicios legales en migración son nuestra apuesta más fuerte
          entre calidad y responsabilidad con el cliente, nuestro equipo
          integrado por Consultores especializados pone a tu disposición los
          siguiente servicios:{' '}
        </p>
        <ul>
          <li>
            <div style={{ fontWeight: 'bold' }}>
              Obtención y Renovación de la Constancia de Inscripción del
              Empleador (CIE)
            </div>
            <div>
              La constancia de inscripción del empleador tramitada ante el
              Instituto Nacional de Migración en México (INM) permite a las
              personas físicas y morales realizar trámites para emitir ofertas
              de empleo a extranjeros.
            </div>
            <h5>¿Qué incluye el pago de honorarios?</h5>
            <ul>
              <li>
                Elaboración de solicitud para Inscripción de Empleador ante
                Instituto Nacional de Migración (INM).
              </li>
              <li>
                Recopilación y envío de información y documentación necesaria
                ante INM.
              </li>
              <li>Asesoría por un especialista en trámites migratorios.</li>
              <li>
                Llenado de las solicitudes para la obtención de Constancia de
                Inscripción del Empleador.
              </li>
              <li>
                Contestación a los requerimientos de forma o fondo que pudiera
                solicitar el INM.
              </li>
              <li>
                Seguimiento de solicitud y trámites hasta concluir y obtener la
                constancia.
              </li>
            </ul>
            <h5>¿Cuál es el proceso?</h5>
            <p>El cliente deberá enviar:</p>
            <ul>
              <li>
                Acta Constitutiva para acreditar legal existencia, así como sus
                modificaciones.
              </li>
              <li>
                Instrumento público en el que conste el tipo de poder o mandato
                y las facultades conferidas a los representantes legales o a los
                apoderados si el Acta Constitutiva no los contiene.
              </li>
              <li>
                Identificación Oficial vigente del representante legal o
                apoderado legal.
              </li>
              <li>
                Comprobante de Domicilio de la persona moral, cuya fecha de
                expedición no exceda de treinta días.
              </li>
              <li>
                Constancia de inscripción en el Registro Federal de
                Contribuyentes y constancia emitida por autoridad competente,
                sobre la presentación de la última declaración de impuestos.
              </li>
              <li>Lista de Empleados y su nacionalidad.</li>
              <li>Comprobante de pago del servicio.</li>
            </ul>
          </li>
          <li>
            <div style={{ fontWeight: 'bold' }}>Visa por oferta de trabajo</div>
            <div>
              Una vez que la compañía cuente con la Constancia de Inscripción
              del Empleador, es necesario realizar una solicitud de visa por
              oferta de trabajo, con la cual el extranjero obtendrá un documento
              con vigencia de un año para realizar actividades remuneradas
              dentro del territorio mexicano.
            </div>
            <div>
              A través de nuestra firma, contará con representación legal
              especializada ante las autoridades mexicanas que invierten en el
              trámite de emisión de visa, romoviendo en su nombre y
              representación los siguientes pasos:
            </div>
            <ul>
              <li>
                Elaboración de expediente migratorio y presentación a las
                oficinas del Instituto Nacional de Migración.
              </li>
              <li>
                Seguimiento periódico de la solicitud de visa y gestión de
                oficio de autorización de entrevista consular.
              </li>
              <li>
                Envío oficial de autorización de visa y documentación requerida
                para el relevo de la entrevista consular.
              </li>
              <li>
                Asesoramiento especializado a extranjeros vía telefónica / zoom
                para exponer las recomendaciones para el correcto relevo de la
                entrevista consular y poder garantizar la emisión de la visa
                mexicana.
              </li>
            </ul>
            <h5>Etapas del proceso de solicitud de visa</h5>
            <ul>
              <li>
                Es necesario contar con la constancia de inscripción del
                empleador tramitada ante el Instituto Nacional de Migración en
                México (INM).
              </li>
              <li>
                Autorización de visa para oferta de trabajo. La citada
                documentación será presentada en las oficinas del Instituto
                Nacional de Migración para que en aproximadamente 45 días
                hábiles se obtenga la aprobación de dicho proceso, con lo cual
                se generará una carta oficial para entrevista consular.
              </li>
              <li>
                Entrevista consular. Una vez recibida la entrevista consular
                oficial, el extranjero tiene 30 días calendario para ventilar la
                entrevista en el país de su elección en el que haya
                representación consular mexicana.
              </li>
              <li>
                Canje de múltiples formas migratorias (FMM) por tarjeta de
                residencia temporal. Una vez que el extranjero aventure con
                éxito la entrevista consular, se sellará la visa mexicana en su
                pasaporte y tendrá 180 días calendario para ingresar a
                territorio mexicano y obtener la tarjeta que certificará su
                condición de residente temporal con permisos para realizar
                actividades remuneradas.
              </li>
            </ul>
            <h5>¿Por qué hacerlo con nosotros?</h5>
            <div>
              En AS Consulting Group tenemos un área especializada en atender a
              empresas y residentes en el extranjero que han optado por venir a
              México a invertir o residir, al día de hoy el 70% de nuestros
              clientes son extranjeros y desde hace más de 10 años hemos
              facilitado su proceso de inversión en nuestro país.
            </div>
          </li>
        </ul>

        <h4>c).- Laboral</h4>
        <ul>
          <li>Despidos</li>
          <li>Demandas laborales</li>
          <li>Contratos laborales</li>
        </ul>
        <h5>Beneficios:</h5>
        <p>
          Como Empresa logras tener un control en los contratos, realizar
          despidos con mayor ética y apegados a la ley, asi como reponder
          demandas de manera correcta deslindandose de alguna reponsabilidad
          ante cualquier situación legal.
        </p>
        <h5>¿Para quién es esta solución?</h5>
        <ul>
          <li>
            Empresas que buscan hacer contratos o tener un control en sus
            contratos.
          </li>
          <li>
            Personas morales que busquen realizar despidos apegados a lo que
            marca la ley.
          </li>
          <li>
            Empresas que busquen responder a demandas de empleado o grupo de
            empledos apegados conforme a lo que marca la ley.
          </li>
        </ul>
        <p>
          Con nuestra solucón de servicios laborales se hace una primer asesoria
          para conocer la situación y se brinda la información sobre lo
          necesario por parte de uno de los asociados de AS Legal , se contrata
          el servicio y se solicita la documentación necesaria para poder
          realizar el servicio para que posteriormente se entreguen los
          documentos solicitados.
        </p>
        <p style={{ marginTop: 24, color: '#aaa' }}>
          <FormattedHTMLMessage id="solutions.legal.content.p2" />
        </p>
      </div>
      <ContractButtonSmall to="/contacto/" small>
        <FormattedMessage id="element.button_hire" />
      </ContractButtonSmall>
    </Section>
  )
}

export default PageContentLegal
