import React from 'react'
import Layout from '../components/Layout'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/legal.png'
import FooterContact from './../components/FooterContact'
import PageContentLegal from '../components/Solutions/Pages/Legal'

const Legal = props => (
  <Layout
    title="Servicio Legal"
    description="Como Bufete Jurídico ofrecemos soluciones legales e integrales, con atención personalizada y objetiva a nuestros clientes, brindando diversas estrategias para la solución de problemas legales en diversas ramas del derecho."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <PageContentLegal />
    <FooterContact />
  </Layout>
)

export default Legal
